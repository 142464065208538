<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Organisations" subtitle="Browse organisations and view them in other modes." />

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no organisations found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Name" />

			</app-table-head>

			<app-table-body>

				<app-table-row :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.name" />
					<app-table-cell-action icon="mode.manager" v-tooltip="'Launch in Manager'" v-on:click="onManagerClick(item.id)" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			layout: 'auto 24px',
			endpoint: 'organisations/organisations',
			live: 'organisations/organisations',
			edit: 'Organisations.Browse.Edit',
		}

	},

	methods: {

		onManagerClick: function(id) {

			this.$api.post('launch', {
				organisation: id,
				mode: 'manager'
			}).then(function(json) {

				window.open(json.url)

			}, function() {

				// failed

			})

		}
	}

}

</script>

<style scoped>

</style>